import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router'
import { ResponsiveService } from './responsive.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  	title = 'Tezos Pittsburgh';

  	currTab = "";

  	isSmallLayout = false;

  	constructor(private router: Router, private responsive: ResponsiveService) { 

		this.router.events.subscribe((event) => { 
			if(event instanceof NavigationEnd) {
				this.currTab = event.url;
			}
		})

		responsive.layoutChanges.subscribe((event) => {
			this.isSmallLayout = event.matches;
		})

	}

}

import{Injectable} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Injectable({
	providedIn: 'root'
})
export class ResponsiveService {
	
	isSmall;
	layoutChanges;

	constructor(private breakpointObserver: BreakpointObserver) {

		this.layoutChanges = this.breakpointObserver.observe(['(max-width: 980px)']);
	}
}
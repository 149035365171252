import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	isSmallLayout = false;

  	constructor(private responsive: ResponsiveService) { 

		responsive.layoutChanges.subscribe((event) => {
			this.isSmallLayout = event.matches;
		})

	}

  ngOnInit() {
  }

}

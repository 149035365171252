import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from '../responsive.service';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {

	isSmallLayout = false;

  	constructor(private responsive: ResponsiveService) { 

		responsive.layoutChanges.subscribe((event) => {
			this.isSmallLayout = event.matches;
		})

	}

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DriversComponent } from './drivers/drivers.component';
import { BusinessComponent } from './business/business.component';
import { InvestComponent } from './invest/invest.component';
import { RoadmapComponent } from './roadmap/roadmap.component';

import { TezComponent } from './tez/tez.component';
import { StakeComponent } from './stake/stake.component';
import { AboutComponent } from './about/about.component';


const routes: Routes = [
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'home', component: HomeComponent },
  { path: 'tez', component: TezComponent },
  { path: 'stake', component: StakeComponent },
  { path: 'drivers', component: DriversComponent },
  { path: 'bus', component: BusinessComponent },
  { path: 'invest', component: InvestComponent },
  { path: 'roadmap', component: RoadmapComponent },
  { path: 'about', component: AboutComponent }
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from '../responsive.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit {

	isSmallLayout = false;

  	constructor(private responsive: ResponsiveService,
    private formBuilder: FormBuilder, private http:HttpClient) { 

		responsive.layoutChanges.subscribe((event) => {
			this.isSmallLayout = event.matches;
		})

    this.driverForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      location: ['', Validators.required],
      years: ['', [Validators.required, Validators.min(0)]],
      note: [''],
    });
	}

  driverForm: FormGroup;

  ngOnInit() {
  }

  private url: string = "http://admensh.com/insertUser.php";

  signUp(formData):Observable<any>{
    console.log(formData);

    return this.http.post<any>(this.url, formData);
  }

  onSubmit(form){
    console.log(form);

    if(this.driverForm.status == "INVALID")
    {
      alert("Please fill in the required fields and re-submit. Thanks!");
    }
    else
    {
      this.signUp(form).subscribe((value) => console.log(value));

      alert("Thank you for the submission! The Admensh Group will reach out with instructions when you are accepted.")
    }

  }

}
